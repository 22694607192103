import React from "react";
import ReactSelect from "react-select";

/**
 * A wrapped ReactSelect component that changes the default styles.
 * @returns 
 */
const Select = (props: any) => {
    return (
        <ReactSelect theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: 'var(--accent-color-75)',
              primary25: 'var(--accent-color-25)',
              primary50: 'var(--accent-color-50)',
              primary75: 'var(--accent-color-75)',
              neutral0: 'var(--primary-background-color)',
              neutral5: 'var(--primary-background-color)',
              neutral10: 'var(--foreground-color-25)',
              neutral20: 'var(--foreground-color-25)',
              neutral30: 'var(--foreground-color-75)',
              neutral40: 'var(--foreground-color-75)',
              neutral50: 'var(--foreground-color-75)',
              neutral60: 'var(--foreground-color-75)',
              neutral70: 'var(--foreground-color-75)',
              neutral80: 'var(--foreground-color-75)',
              neutral90: 'var(--foreground-color-75)',
            },
          })} {...props} />
    )
};

export default Select;