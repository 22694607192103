import { graphql, Link } from "gatsby";
import { IGatsbyImageData } from "gatsby-plugin-image";
import React, { useEffect, useState } from "react";
import FadeInWrapper from "../components/animated/fade-wrapper";
import Image from "../components/animated/image";
import Select from "../components/controls/select";
import Layout from "../components/layout/page-layout";
import * as styles from "../styles/modules/projects.module.css";
import { ContentfulProject } from "../types/graphql-types";

/**
 * Data from graphql query
 */
interface Props {
  data: {
    allContentfulProject: {
      nodes: ContentfulProject[];
    };
  };
}

// Creates a list of sort options.
const sortByOptions: { label: string; value: string }[] = [
  { label: "Most Recent", value: "date" },
  { label: "Least Recent", value: "date-desc" },
  { label: "Name (A-Z)", value: "name" },
  { label: "Name (Z-A)", value: "name-desc" },
];

const Projects = ({ data }: Props) => {
  const allProjects = data.allContentfulProject.nodes;
  const transitionSpeed = 0.1;

  // All page states for sorting and filtering.
  // Sets projects to null until it has been filtered and sorted with the default values.
  const [projects, setProjects] = useState<ContentfulProject[] | null>(null);
  const [projectsFilter, setProjectsFilter] = useState<[{name: string, value: string}] | null>(null);
  const [projectsSort, setProjectsSort] = useState<{label: string, value: string} | null>(null);

  // Creates a list of tags from all projects.
  // This is used to populate the select dropdown so a user can filter projects by tags.
  let options: Set<string> = new Set();
  projects?.forEach((project) => {
    project.tags?.forEach((tag) => {
      options.add(tag as string);
    });
  });
  const tagOptions = Array.from(options).sort().map((tag) => { return { value: tag, label: tag } });

  // Performs the default filter and sort.
  useEffect(() => {
    setProjectsSort(sortByOptions[0]);
    handleProjects(sortByOptions[0], projectsFilter);
  }, []);

  /**
   * Filters projects by tag.
   * @param selectedOptions 
   */
  function handleFilterChange(selectedOptions: any) {
    setProjectsFilter(selectedOptions);
    handleProjects(projectsSort, selectedOptions);
  }

  /**
   * Sorts projects by date or name.
   * @param selectedOption 
   */
  function handleSortChange(selectedOption: any) {
    setProjectsSort(selectedOption);
    handleProjects(selectedOption, projectsFilter);
  }

  function handleProjects(projectsSort: any, projectFilters: any) {
    // Copies all projects to a new array.
    // This is so we can sort and filter the projects without modifying the original array.
    // We don't want to modify the original array because it would affect the state of the component.
    let newProjects = [...allProjects];

    // Handles filtering.
    // If no filter is selected, all projects are shown.
    // Otherwise, only projects with the selected filter are shown.
    if (projectFilters && projectFilters.length > 0) {
      for (let filter of projectFilters) {
        newProjects = newProjects.filter((project) => {
          return project.tags?.includes(filter.value);
        });
      }
    }

    // Handles sorting.
    // If no sort is selected, all projects are shown.
    if (projectsSort && projectsSort.value) {
      newProjects = newProjects.sort((a, b) => {
        if (projectsSort.value === "date") {
          return b.creationDate?.localeCompare(a.creationDate) || b.createdAt.localeCompare(a.createdAt);
        } else if (projectsSort.value === "date-desc") {
          return a.creationDate?.localeCompare(b.creationDate) || a.createdAt.localeCompare(b.createdAt);
        } else if (projectsSort.value === "name") {
          return a.name?.localeCompare(b.name as string);
        } else if (projectsSort.value === "name-desc") {
          return b.name?.localeCompare(a.name as string);
        }
      });
    }

    setProjects(newProjects);
  }


  return (
    <Layout title="Projects">
      <FadeInWrapper>
        <div className="row mb-2">
          <div className="col">
            <label>Filter by Tags</label>
            <Select options={tagOptions} isMulti={true} onChange={handleFilterChange} />
          </div>
          <div className="col">
            <label>Sort by</label>
            <Select options={sortByOptions} defaultValue={sortByOptions[0]} onChange={handleSortChange} />
          </div>
        </div>
        <div className="row">
          <FadeInWrapper transitionSpeed={transitionSpeed}>
            {projects?.map((project: ContentfulProject) => {

              // Retrieves the image and alt tag so that each project has a lazy loading image 
              // with an alt tag that is the project name.
              const imageData: IGatsbyImageData = project.headerImage?.gatsbyImageData;
              const imageAlt: string = `${project.headerImage?.description != '' ? project.headerImage?.description : project.name}`;

              return (
                // The key is generated so that the component is re-rendered when the projects filter / sort is updated,
                // however, it will not change when they remain the same but are changed.
                <div className={`col max col-3 `} key={`${project.slug}-${projectsFilter?.map((option: any) => option.value).join('-')}-${projectsSort?.value}`}>
                  <Link to={`/project/${project.slug}`} className={`${styles.projectCard}`}>
                    <Image image={imageData} alt={imageAlt} />
                    <h2 className={styles.projectCardHeading}>{project.name}</h2>
                    <p className={styles.projectCardDate}>{new Date(project.creationDate ?? project.createdAt).toLocaleDateString('en-US', { year: 'numeric', month: 'long' })}</p>
                    <p className={styles.projectCardDescription}>{project.description?.description}</p>
                  </Link>
                </div>
              );
            })}
          </FadeInWrapper>
        </div>
      </FadeInWrapper>
    </Layout>
  )
};

export const query = graphql`
  query {
    allContentfulProject {
      nodes {
        slug
        name
        projectUrl
        creationDate
        createdAt
        headerImage {
          gatsbyImageData(placeholder: BLURRED, formats: PNG)
          description
        }
        description {
          description
        }
        tags
      }
    }
  }
`;

export default Projects;