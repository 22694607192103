import { motion, useAnimation } from "framer-motion";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import * as React from "react";

interface Props {
  image: IGatsbyImageData;
  alt: string;
}

const Image = ({ image, alt }: Props) => {
  const backgroundControl = useAnimation();
  const imageControl = useAnimation();

  const onHover = () => {
    backgroundControl.start({
      x: 10,
      y: -10
    });

    imageControl.start({
      x: -2,
      y: 2
    });
  };

  const onLeave = () => {
    backgroundControl.start({
      x: 0,
      y: 0
    });

    imageControl.start({
      x: 0,
      y: 2
    });
  };

  return (
    <div style={{position: 'relative'}} onMouseOver={onHover} onMouseLeave={onLeave}>
      <motion.div style={{ position: "absolute", opacity: "30%" }} animate={backgroundControl}>
        <GatsbyImage className="img-fluid img-rounded" image={image} alt={alt} />
      </motion.div>
      <motion.div animate={imageControl}>
        <GatsbyImage className="img-fluid img-rounded" image={image} alt={alt} />
      </motion.div>
    </div>
  );
};

export default Image;
